import React from 'react';

import { Layout } from '../../components/layout';
import SEO from '../../components/seo';

export default function FrontendDeveloper(): JSX.Element {
  return (
    <Layout>
      <SEO title="Front-end Developer" />
      <div className="container">
        <nav className="breadcrumb">
          <a href="/#jobs">Jobs</a>
          <span>Front-end Developer</span>
        </nav>
        <h2>Front-end Developer (Fulltime / Remote)</h2>
        <h4>About us</h4>
        <p>
          We are a product development studio based in Berlin working on
          products for promising startups and help established companies
          innovating on their products and services. We work in agile,
          multi-disciplinary teams which helps us solve business problems for
          our clients by building the right product. Also we value the work that
          other developers share as open source on github and we try to give
          back as much as possible by contributing and hosting community events
          such as swift.berlin.
        </p>
        <h4>Your Job</h4>
        <p></p>
        <ul>
          <li>
            Collaborate with our designers, backend developers and clients on
            creating and maintaining quality interfaces with well structured
            code bases
          </li>
          <li>
            Own small-to-medium projects yourself and collaborate with other
            developers on larger and more complex projects
          </li>
          <li>
            Choose and help us choose the right tool for the job. Currently
            we’re using React, Redux, Webpack a lot - but are also open for
            changes to the stack in the future
          </li>
          <li>
            Anticipate product development issues and proactively seek solutions{' '}
          </li>
        </ul>
        <p />
        <h4>Your Skillset</h4>
        <p></p>
        <ul>
          <li>
            At least 3 years of practical experience with modern Javascript code
            including module/bundler systems and using frameworks such as React,
            Vue, etc. + unit testing with mocha, jest, jasmine, etc.
          </li>
          <li>
            Fluency in modern CSS, including using preprocessors. Experience
            with responsive layouts Flexbox and CSS Grid
          </li>
          <li>
            Decent communication skills and fluency in English language. German
            would be nice as well but is not required.
          </li>
          <li>
            Note: You don’t have to be an expert in everything but we expect you
            to be eager to learn and adapt to upcoming technologies and
            processes
          </li>
          <li>
            Oh and.. it’s a plus if you aren’t afraid of backend technologies
            such as Ruby on Rails, Node etc.
          </li>
        </ul>
        <p />
        <h4>Your character</h4>
        <p></p>
        <ul>
          <li>Be passionate about development.</li>
          <li>
            Have a strong appetite to explore new technologies and share
            knowledge.
          </li>
          <li>Be outgoing, personable, sociable and easy going.</li>
          <li>
            Welcome feedback and look to improve project development and
            understanding.
          </li>
          <li>Be able to work to deadlines and in a fast-paced environment.</li>
        </ul>
        <p />
        <p>
          <a className="link" href="mailto:hiring@nerdgeschoss.de">
            Sounds interesting? Let's talk
          </a>
        </p>
      </div>
    </Layout>
  );
}
